import React, { useEffect, useState, useContext, Fragment } from "react";
import {
    getStatistics,
    validateUserAndRole,
    authenticateUserSSOByPassCache,
    sendAccessRequest,
    checkIsAdmin,
} from "../../services/aws-http-services";
import history from "../../utils/history";
import { setItem, removeItem } from "../../utils/LocalStorage";
import CountUp from "react-countup";
import { getAutodeskModule, navigateToSelectedModuleDashboard } from "../../common/common-service";
import { TOAST_LIST } from "../../config/toast.config";
import Toast from "../../components/Toast";
import Feedback from "../../components/Feedback";
import Button from "../../components/Button";
import CustomModal from "../../components/CustomModal";
import { AuthContext } from "../../context/index";
import { BrowserTitleContext } from "../../context/browserTitle";
import { cognitoConfig } from "../../config/cognito.config";
import moment from "moment";
import "./home.scss";

const Home = () => {
    const [stat, setStat] = useState([]);
    const [requests, setRequests] = useState(0);
    const [hours, setHours] = useState(0);
    const [toastList, setToastList] = useState([]);
    const [formValue, setFormValue] = useState({ userName: "", reason: "" });
    const [errorMsg, setErrMsg] = useState("");
    const [error, showError] = useState(false);
    const [btnRequired, setBtnRequired] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [elements, setElements] = useState([]);
    const [elementName, setElementName] = useState("");
    const [disableClicks, setDisableClicks] = useState(false);
    const [maintenance, setMaintenance] = useState([]);

    const { setAuthStatus } = useContext(AuthContext);
    const { browserTitle, setBrowserTitle } = useContext(BrowserTitleContext);

    const fsotElements = [
        // {
        //     name: "Spotlight",
        //     module: "Spotlight",
        //     description: "Auditing Module",
        //     icon: <img src="assets/img/spotlight.png" alt="Spotlight" width="70" height="70" />,
        // },
        {
            name: "Price Hub",
            module: "Price Hub",
            description: "AI Pricing. Previously Agent Assist",
            icon: <img src="assets/img/2-price-hub.png" alt="Price Hub" width="70" height="70" />,
        },
        // {
        //     name: "Watch Dog",
        //     module: "Watch Dog",
        //     description: "Export Compliance Screening",
        //     icon: <i className="fas fa-dog"></i>,
        // },
        // {
        //     name: "Checkup",
        //     module: "Healthcheck Dashboard",
        //     description: "Premier Accounts Healthcheck",
        //     icon: <img src="assets/img/hc-icon.png" alt="Healthcheck" width="60" height="60" />,
        // },
        {
            name: "Fringe",
            module: "Fringe Dashboard",
            description: "Fringe Forecast Dashboard",
            icon: <img src="assets/img/fringe-icon-new.png" alt="Fringe" width="70" />,
        },
        {
            name: "Vault",
            module: "Vault Dashboard",
            description: "Vault Forecast Dashboard",
            icon: <img src="assets/img/vault-logo-1.png" alt="Vault" width="40" />,
        },
        {
            name: "Accrucial",
            module: "Accrucial Dashboard",
            description: "Accounting Accrual Platform",
            icon: <img src="assets/img/accrucial-icon-1.png" alt="Vault" width="40" />,
        },
        {
            name: "AWS Cloud Cost",
            module: "AWS Cloud Cost Forecast Dashboard",
            description: "AWS Cloud Cost Forecast Dashboard",
            icon: <img src="assets/img/aws-cloud-icon.png" alt="AWS-CC" width="50" />,
        },
        // {
        //     name: "Tax Trend Analysis",
        //     module: "Tax Trend Analysis Forecast Dashboard",
        //     description: "Tax Trend Analysis Forecast and Dashboard",
        //     icon: (
        //         <img
        //             src="assets/img/tax-logo.png"
        //             alt="Tax Trend Analysis"
        //             width="30"
        //             style={{ top: "25%" }}
        //         />
        //     ),
        // },
        {
            name: "Lease recon",
            module: "Lease Recon",
            description: "Lease Recon",
            icon: <i className="fas fa-building"></i>,
        },
        {
            name: "Priority Sustainability Solutions",
            module: "ESG Dashboard",
            description: "Adoption Dashboard",
            icon: <img src="assets/img/sustainability.png" alt="ESG Dashboard" width="40" />,
        },
        {
            name: "Merlin",
            module: "ACV Forecasting Dashboard",
            description: "Billings Metrics Forecasting Dashboard",
            icon: <i className="fas fa-file-invoice" style={{ color: "#cbced7" }}></i>,
        },
        {
            name: "Forecast Platform",
            module: "Forecast Platform",
            description: "Forecast Platform",
            icon: <i className="fas fa-chart-line" style={{ color: "#cbced7" }}></i>,
        },
        // {
        //     name: "Applause Spend Platform",
        //     module: "Applause Spend Platform",
        //     description: "Applause Spend Platform",
        //     icon: (
        //         <img src="assets/img/applause-tile.png" alt="Applause Spend Platform" width="40" />
        //     ),
        // },
        {
            name: "Cash Fund Forecast",
            module: "Cash Fund Forecast",
            description: "Cash Fund Forecast",
            icon: <i className="fas fa-money-check-alt" style={{ color: "#cbced7" }}></i>,
        },
        {
            name: "Tax recon",
            module: "Tax Recon",
            description: "Tax Recon",
            icon: <i className="fas fa-hand-holding-usd"></i>,
        },
        {
            name: "Seesaw",
            module: "Seesaw",
            description: "Seesaw",
            icon: <img src="assets/img/seesaw-img.png" alt="Applause Spend Platform" width="40" />,
        },
        {
            name: "Meeting Digest",
            module: "Meeting Digest",
            description: "Generative AI Powered Meeting Notetaker Tool",
            icon: <i className="fas fa-calendar-day"></i>,
        },
        {
            name: "Lumi Topics",
            module: "Lumi Topics",
            description: "Lumi Topics",
            icon: <img src="assets/img/lumi-logo.png" alt="Lumi Topics" width="60" />,
        },
        // {
        //     name: "FinBot",
        //     module: "FinBot",
        //     description: "FinBot",
        //     icon: <i className="fas fa-robot"></i>,
        // },
    ];

    const accrucialElements = [
        {
            name: "T&E Accrucial",
            module: "T&E Accrucial",
            description: "Travel and Expense Dashboard",
        },
        {
            name: "AIP Accrucial",
            module: "AIP Accrucial",
            description: "Annual Incentive Program Dashboard",
        },
        {
            name: "FBP Accrucial",
            module: "FBP Accrucial",
            description: "FBP Dashboard",
        },
    ];

    const merlinForecastingElements = [
        {
            name: "Topline",
            module: "Merlin Forecasting Topline",
            description: "Billings Metrics Forecasting Dashboard",
        },
        {
            name: "AMER",
            module: "Merlin Forecasting AMER",
            description: "Billings Metrics Forecasting Dashboard",
        },
    ];

    const _handleSetModuleType = async (e) => {
        const ad_module = e.currentTarget.getAttribute("data-value");

        // console.log(ad_module, ad_module.includes("Accrucial"));
        if (maintenance.includes(ad_module)) {
            setBtnRequired(false);
            setToastList([
                {
                    ...TOAST_LIST.danger,
                    title: `Module is under maintenance. Please try again later.`,
                },
            ]);
            return;
        }

        if (ad_module.includes("Accrucial Dashboard")) {
            setElements(accrucialElements);
            setElementName("accrucial");
            $("#selectElements").modal("show");
        } else if (ad_module.includes("ACV Forecasting Dashboard")) {
            setElements(merlinForecastingElements);
            setElementName("merlin");
            $("#selectElements").modal("show");
        } else {
            setItem("ad_module", btoa(ad_module));
            setBrowserTitle(`Autodesk | ${ad_module}`);
            setLoading(true);
            try {
                const user = await authenticateUserSSOByPassCache(setAuthStatus);
                if (user && user.auth) {
                    const isUserHasValidRole = await validateUserAndRole(user.groups);
                    setItem("username", user.user.username);
                    const isAdmin = await checkIsAdmin(user.groups);

                    if (isAdmin) setItem("isAdmin", true);

                    if (isUserHasValidRole) {
                        $("#selectElements").modal("hide");
                        navigateToSelectedModuleDashboard();
                    } else {
                        setToastList([
                            {
                                ...TOAST_LIST.danger,
                                title: `User does not belongs to ${getAutodeskModule()}`,
                            },
                        ]);
                        // removeItem("ad_module");
                    }
                } else {
                    console.log("not signed in");
                    window.location.assign(cognitoConfig.ssoUrl);
                }
            } catch (err) {
                console.log(err);
                if (err.includes("not auth")) {
                    window.location.assign(cognitoConfig.ssoUrl);
                }
                console.log("error in entering module", err);
            } finally {
                setLoading(false);
            }
        }
    };

    const triggerSlide = () => {
        $("#carouselExampleControls").carousel({
            interval: 3000,
            cycle: true,
        });
    };

    const handleShowRequestAccessModal = () => {
        $("#selectElements").modal("hide");
        $("#requestAccess").modal("show");
    };

    const handleFormValue = (e) => {
        const { name, value } = e.target;
        showError(false);
        setErrMsg("");
        setFormValue({ ...formValue, [name]: value });
    };

    const handleRequestAccessSubmit = async () => {
        const { userName, reason } = formValue;
        setDisableSubmit(true);
        if (userName === "" || reason === "") {
            showError(true);
            setErrMsg("Please enter all required fields");
            setDisableSubmit(false);
        } else {
            const payload = {
                appName: getAutodeskModule() || "FSOTAI Dashboard",
                createdDate: moment().format("MM-DD-YYYY HH:mm:ss Z"),
                userName,
                reason,
            };
            setBtnRequired(false);
            setToastList([{ ...TOAST_LIST.info, title: `Submitting request...` }]);
            try {
                await sendAccessRequest(payload);
                setToastList([{ ...TOAST_LIST.success, title: `Request sent successfully` }]);
            } catch (err) {
                console.log(err);
                setToastList([{ ...TOAST_LIST.danger, title: `Request send failed` }]);
            }
            setTimeout(() => {
                setFormValue({ userName: "", reason: "" });
                $("#requestAccess").modal("hide");
            }, 500);
            setTimeout(() => {
                setBtnRequired(true);
            }, 4000);
            setDisableSubmit(false);
            removeItem("ad_module");
        }
    };

    const fetchStatistics = async () => {
        setDisableClicks(true);
        try {
            const response = await getStatistics();
            // console.log("response", response);
            const statistics = response.statistics;
            setMaintenance(response.maintenance);
            setStat(statistics);
            setRequests(Math.round(statistics[statistics.length - 1]["totalRequests"]));
            setHours(Math.round(statistics[statistics.length - 1]["totalMinutes"] / 60));
        } catch (err) {
            console.log(err);
            if (err.includes("not auth")) {
                window.location.assign(cognitoConfig.ssoUrl);
            }
        }
        setDisableClicks(false);
    };

    useEffect(() => {
        document.title = browserTitle;
        fetchStatistics();
        triggerSlide();
    }, []);

    useEffect(() => {
        const $elem = $(".fade-scale.show");

        if ($elem.length) {
            if (loading) $("#selectElements").modal("hide");
            // $elem[0].style.setProperty("opacity", "0", "important");
            else $("#selectElements").modal("show"); // $elem[0].style.setProperty("opacity", "1", "important");
        }
    }, [loading]);

    // useEffect(() => {}, [requests]);

    return (
        <div className="components-container">
            {loading ? (
                <div className="fadeMe">
                    <p>Loading application please wait....</p>
                    <div id="loader">
                        <div className="application-loading-container">
                            <div className="application-loading-box">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <CustomModal
                modalName={"requestAccess"}
                modalSize={"modal-sm"}
                modalCustomClass={"feedback-modal-wrapper"}
                animation={"fade-scale"}
            >
                <div className="modal-header d-flex feedback-icons-wrapper justify-content-between">
                    <h5 className="modal-title feedback-title" id="customModalLabel">
                        Reason for access request
                    </h5>
                    <Button
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        children={<span aria-hidden="true">&times;</span>}
                    />
                </div>
                <div className="modal-body">
                    <form className="feedback-form request-access-form">
                        <div className="form-group custom-dialog-input">
                            <input
                                type="text"
                                name="userName"
                                value={formValue.userName}
                                onChange={handleFormValue}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                placeholder="Email"
                            />
                        </div>
                        <div className="form-group custom-dialog-container">
                            <textarea
                                maxLength="50"
                                name="reason"
                                value={formValue.reason}
                                onChange={handleFormValue}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                rows="5"
                                placeholder="Please type why you need to access module"
                            ></textarea>
                        </div>
                        {error ? <p className="error-message">{errorMsg}</p> : null}
                    </form>
                </div>
                <div className="modal-footer">
                    <Button
                        text={"Submit"}
                        className="btn btn-primary align-self-center mx-auto feedback-button"
                        onClick={handleRequestAccessSubmit}
                        disabled={disableSubmit}
                    />
                    {/* <button type="button" onClick={handleFeedbackSubmit} className="btn btn-primary align-self-center mx-auto feedback-button">Submit</button> */}
                </div>
            </CustomModal>
            <CustomModal
                modalName={"selectElements"}
                modalSize={"modal-lg"}
                modalCustomClass={"accrucial-selection"}
                animation={"fade-scale"}
            >
                <div className="modal-header d-flex feedback-icons-wrapper justify-content-between">
                    <h5 className="modal-title feedback-title" id="customModalLabel">
                        Select any {elementName}
                    </h5>
                    <Button
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        children={<span aria-hidden="true">&times;</span>}
                    />
                </div>
                <div className="modal-body">
                    <div className="l-grid-container">
                        {elements.map((ele, idx) => {
                            return (
                                <div
                                    data-value={ele.module}
                                    key={idx}
                                    onClick={(e) => _handleSetModuleType(e)}
                                    className="card border-0"
                                >
                                    <div className="card-body">
                                        <h5 className="card-title">{ele.name}</h5>
                                        <p className="card-text">{ele.description}</p>
                                        <a className="btn btn-link rounded-circle scale-on-hover">
                                            <i className="fas fa-rocket"></i>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CustomModal>
            <Toast
                toastList={toastList}
                position={"top-right"}
                autoDelete={!btnRequired}
                dismissTime={parseInt(3000, 10)}
                btnRequired={btnRequired}
                btnCallback={handleShowRequestAccessModal}
            />
            <div className="container-fluid">
                <div className="l-logo">
                    <a className="navbar-brand" style={{ padding: 0 }}>
                        <img src="assets/img/autodesk-icon-black.png" alt="Logo" />
                        <h4 className="brand-name">Autodesk</h4>
                    </a>
                    {/* <a>
                        <img src="assets/img/autodesk-logo-primary.png" alt="Logo" />
                    </a> */}
                    <div className="fill-remaining-space"></div>
                    {/* <Button
                        onClick={() => history.push("/about-us")}
                        className="btn btn-primary"
                        text="About Us"
                    /> */}
                    {/* <Button
                        onClick={() => history.push("/rakesh-demo")}
                        className="btn btn-primary mr-3"
                        text="Rakesh Demo"
                    /> */}
                    <Button
                        onClick={() => history.push("/demo-dashboards")}
                        className="btn btn-primary"
                        text="Demo Dashboards"
                    />
                </div>
                <div className="row align-items-center justify-content-center height-88">
                    <div className="ad-modules">
                        <div className="l-grid-container">
                            {fsotElements.reverse().map((ele, idx) => {
                                return (
                                    <div
                                        data-value={ele.module}
                                        className="module-tile"
                                        key={idx}
                                        onClick={(e) =>
                                            disableClicks ? {} : _handleSetModuleType(e)
                                        }
                                    >
                                        <div className="module-logo">{ele.icon}</div>
                                        <div className="module-content">
                                            <h2 className="module-title">{ele.name}</h2>
                                            <p className="module-description">{ele.description}</p>
                                        </div>
                                    </div>
                                    // <div
                                    //         data-value={ele.module}
                                    //         style={{ cursor: "pointer" }}
                                    //         key={idx}
                                    //         onClick={(e) => _handleSetModuleType(e)}
                                    //         className="card border-0"
                                    //     >
                                    //         <div className="card-placeholder">{ele.icon}</div>
                                    //         <div className="card-body">
                                    //             <h5 className="card-title">{ele.name}</h5>
                                    //             <p className="card-text">{ele.description}</p>
                                    //             <a className="btn btn-link rounded-circle scale-on-hover">
                                    //                 <i className="fas fa-rocket"></i>
                                    //             </a>
                                    //         </div>
                                    //     </div>
                                );
                            })}

                            {/* <div className="card border-0 disabled">
                                <div className="card-placeholder">
                                    <i className="fas fa-dollar-sign"></i>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Coming Soon</h5>
                                    <p className="card-text"></p>
                                    <a className="btn btn-link rounded-circle">
                                        <i className="fas fa-rocket"></i>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="br-light">
                        <div className="statistics-container">
                            <div className="params-container">
                                <div className="request-container">
                                    <div id="requests" className="number">
                                        <CountUp duration={1.5} end={requests} separator="," />
                                    </div>
                                    <div className="text">requests handled</div>
                                </div>

                                <div className="time-saved-container">
                                    <div id="hours" className="number">
                                        <CountUp duration={1.5} end={hours} separator="," />
                                    </div>
                                    <div className="text">hours saved so far</div>
                                </div>
                            </div>
                            <div className="carousel-container">
                                <i className="fas fa-quote-left quote"></i>
                                <div
                                    id="carouselExampleControls"
                                    className="carousel slide "
                                    data-ride="carousel"
                                    data-interval="4000"
                                >
                                    <div className="carousel-inner">
                                        {stat.length > 0 &&
                                            stat.slice(0, 4).map((item, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className={`carousel-item ${
                                                            idx === 0 ? "active" : ""
                                                        }`}
                                                    >
                                                        <div key={idx} className="text-sm">
                                                            {item.module} saved{" "}
                                                            {Math.floor(Number(item.minutes) / 60)}{" "}
                                                            hours by handling {item.requests}{" "}
                                                            requests{" "}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Feedback userGroup="FSOT AI" position="bottom" />
            </div>
        </div>
    );
};

export default Home;
