import React, { createContext } from "react";
import { useBrowserTitle } from "../custom-hooks/BrowserTitle";
import { getAutodeskModule } from "../common/common-service";

export const BrowserTitleContext = createContext({
    browserTitle: "Autodesk | FSOT AI Dashboard",
    setBrowserTitle: () => {},
});

export const BrowserTitleContextProvider = ({ children }) => {
    const { browserTitle, setBrowserTitle } = useBrowserTitle(
        `Autodesk | ${getAutodeskModule() || "FSOT AI Dashboard"}`,
    );

    return (
        <BrowserTitleContext.Provider
            value={{
                browserTitle,
                setBrowserTitle,
            }}
        >
            {children}
        </BrowserTitleContext.Provider>
    );
};
