import React, { Fragment, useState } from "react";
import CustomModal from "../CustomModal";
import Toast from "../Toast";
import Button from "../Button";
import PropTypes from "prop-types";

import moment from "moment";
import "./feedback.scss";
import { sendFeedback } from "../../services/aws-http-services";
import { TOAST_LIST } from "../../config/toast.config";
import { getAutodeskModule } from "../../common/common-service";

const Feedback = ({ userGroup, position }) => {
    const charLength = 250;
    const [formValue, setFormValue] = useState({ username: "", message: "" });
    const [toastList, setToastList] = useState([]);
    const [errorMsg, setErrMsg] = useState("");
    const [error, showError] = useState(false);
    const [feedbackType, setFeedbackType] = useState(["issue", "new_idea", "feedback"]);
    const [selectedFeedbackType, setSelectedFeedbackType] = useState(feedbackType[0]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [maxCharLength, setMaxCharLength] = useState(charLength);
    const positionCheck = position === "bottom";

    const handleFeedbackFormValue = (event) => {
        const { name, value } = event.target;
        showError(false);
        setErrMsg("");
        setMaxCharLength(charLength - value.length);
        setFormValue({ ...formValue, [name]: value });
    };

    const handleShowModal = () => {
        showError(false);
        $(`#feedbackForm`).modal("show");
    };

    const handleFeedbackSubmit = async () => {
        const { username, message } = formValue;
        setDisableSubmit(true);

        if (username === "" || message === "") {
            showError(true);
            setErrMsg("Please enter all required fields");
            setDisableSubmit(false);
        } else {
            const payload = {
                appName: getAutodeskModule() || "FSOTAI Dashboard",
                createdDate: moment().format("MM-DD-YYYY HH:mm:ss Z"),
                feedbackDescription: message,
                feedbackType: selectedFeedbackType,
                userGroup: userGroup,
                userName: username,
            };
            setToastList([{ ...TOAST_LIST.info, title: `Submitting feedback...` }]);
            try {
                const sendFeedbackData = await sendFeedback(payload);
                // console.log(sendFeedbackData)
                setToastList([{ ...TOAST_LIST.success, title: `Feedback submitted successfully` }]);
            } catch (err) {
                console.log(err);
                setToastList([{ ...TOAST_LIST.danger, title: `Feedback submission failed` }]);
            }
            setTimeout(() => {
                setFormValue({ username: "", message: "" });
                setMaxCharLength(charLength);
                $("#feedbackForm").modal("hide");
            }, 500);
            setDisableSubmit(false);
        }
    };

    return (
        <Fragment>
            <div className={`${positionCheck ? "feedback-dashboard-wrapper" : "feedback-wrapper"}`}>
                <Button
                    className={`btn btn-default ${
                        positionCheck ? "feedback-dashboard-button" : "feedback-button"
                    }`}
                    onClick={handleShowModal}
                    children={
                        positionCheck ? (
                            <Fragment>
                                {/* <Button text="Feedback" className="feedback-btn">
                                    <i className="far fa-comment" aria-hidden="true"></i>
                                </Button> */}
                                <i className="far fa-comment" aria-hidden="true"></i>
                                <span className="feedback-btn">FEEDBACK</span>
                            </Fragment>
                        ) : (
                            <span>FEEDBACK</span>
                        )
                    }
                />
                {/* <button className="btn btn-default feedback-button" data-toggle="modal" data-target="#feedbackForm"></button> */}
            </div>
            <CustomModal
                modalName={"feedbackForm"}
                modalSize={"modal-sm"}
                modalCustomClass={"feedback-modal-wrapper"}
                animation={positionCheck ? "slide-in-bottom" : "fade-scale"}
            >
                <div className="modal-header d-flex feedback-icons-wrapper justify-content-between">
                    <h5 className="modal-title feedback-title" id="customModalLabel">
                        AI FEEDBACK
                    </h5>
                    <Button
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        children={<span aria-hidden="true">&times;</span>}
                    />
                    {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button> */}
                </div>
                <div className="feedback-icon-q">
                    <i className="fas fa-quote-left feedback-quote"></i>
                </div>
                <span className="feedback-desc">All Welcome !</span>
                <span className="feedback-desc">Feedbacks, Comments and Suggestions</span>
                <div className="modal-body">
                    <form className="feedback-form">
                        <div className="form-group custom-dialog-input">
                            <input
                                type="text"
                                name="username"
                                value={formValue.username}
                                onChange={handleFeedbackFormValue}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                placeholder="Name or Email"
                            />
                        </div>
                        <div className="form-group custom-dialog-dropdown">
                            <div className="dropdown">
                                <button
                                    className="btn btn-secondary dropdown-toggle text-capitalize"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {selectedFeedbackType.replace("_", " ")}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {feedbackType.map((types) => (
                                        <a
                                            key={types}
                                            onClick={() => setSelectedFeedbackType(types)}
                                            className={`dropdown-item text-capitalize ${
                                                types == selectedFeedbackType ? "active-item" : ""
                                            }`}
                                        >
                                            {types.replace("_", " ")}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="form-group custom-dialog-container">
                            <textarea
                                rows="5"
                                cols="50"
                                maxLength={charLength}
                                name="message"
                                value={formValue.message}
                                onChange={handleFeedbackFormValue}
                                className={`form-control ${error ? "is-invalid" : ""}`}
                                placeholder="I wish there is a way to"
                            ></textarea>
                            <p>Remaining : {maxCharLength}</p>
                        </div>
                        {error ? <p className="error-message">{errorMsg}</p> : null}
                    </form>
                </div>
                <div className="modal-footer">
                    <Button
                        text={"Submit"}
                        className="btn btn-primary align-self-center mx-auto feedback-button"
                        onClick={handleFeedbackSubmit}
                        disabled={disableSubmit}
                    />
                    {/* <button type="button" onClick={handleFeedbackSubmit} className="btn btn-primary align-self-center mx-auto feedback-button">Submit</button> */}
                </div>
            </CustomModal>
            <Toast
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                dismissTime={parseInt(3000, 10)}
            />
        </Fragment>
    );
};

Feedback.defaultProps = {
    userGroup: "FSOT AI Hub",
    position: "right",
};

Feedback.propTypes = {
    userGroup: PropTypes.string,
    position: PropTypes.string,
};

export default Feedback;
