import { AutodeskModulesStrings } from "../constants/autodesk-modules";
import history from "../utils/history";
import { getItem } from "../utils/LocalStorage";

export const getAutodeskModule = () => {
    return atob(getItem("ad_module"));
};

export const navigateToSelectedModuleDashboard = () => {
    const moduleType = getAutodeskModule();
    // console.log(moduleType)
    switch (moduleType) {
        case AutodeskModulesStrings.AgentAssist:
            history.push("/price-hub");
            break;
        case AutodeskModulesStrings.WatchDog:
            history.push("/watch-dog");
            break;
        case AutodeskModulesStrings.SalesDashboard:
            history.push("/sales-dashboard");
            break;
        case AutodeskModulesStrings.ExpenseDashboard:
            history.push("/expense-dashboard");
            break;
        case AutodeskModulesStrings.BlackSwan:
            history.push("/spotlight");
            break;
        case AutodeskModulesStrings.Healthcheck:
            history.push("/checkup");
            break;
        case AutodeskModulesStrings.Fringe:
            history.push("/fringe");
            break;
        case AutodeskModulesStrings.Vault:
            history.push("/vault");
            break;
        case AutodeskModulesStrings.TEAccrucial:
            history.push("/accrucial/te-accrucial");
            break;
        case AutodeskModulesStrings.AIPAccrucial:
            history.push("/accrucial/aip-accrucial");
            break;
        case AutodeskModulesStrings.FBPAccrucial:
            history.push("/accrucial/fbp-accrucial");
            break;
        case AutodeskModulesStrings.AWSCCForecast:
            history.push("/aws-cc-forecast");
            break;
        case AutodeskModulesStrings.TaxTrendAnalysis:
            history.push("/tax-trend-analysis");
            break;
        case AutodeskModulesStrings.LeaseRecon:
            history.push("/lease-recon");
            break;
        case AutodeskModulesStrings.ESGDashboard:
            history.push("/esg-dashboard");
            break;
        case AutodeskModulesStrings.ACVForecastingDashboardTopline:
            history.push("/merlin-forecasting/topline");
            break;
        case AutodeskModulesStrings.ACVForecastingDashboardAMER:
            history.push("/merlin-forecasting/amer");
            break;
        case AutodeskModulesStrings.ForecastPlatform:
            history.push("/forecast-platform");
            break;
        case AutodeskModulesStrings.ApplauseSpendPlatform:
            history.push("/applause-spend-platform");
            break;
        case AutodeskModulesStrings.CashFundForecast:
            history.push("/payroll-cash-fund");
            break;
        case AutodeskModulesStrings.TaxRecon:
            history.push("/tax-recon/emea");
            break;
        case AutodeskModulesStrings.Seesaw:
            history.push("/seesaw");
            break;
        case AutodeskModulesStrings.MeetingDigest:
            history.push("/meeting-digest");
            break;
        case AutodeskModulesStrings.LumiTopics:
            history.push("/lumi-topics");
            break;
        case AutodeskModulesStrings.Finbot:
            history.push("/finbot");
            break;
        default:
            history.push("/");
            break;
    }
};
