const local = {
    production: false,
    userPoolId: "us-west-2_ARR238dcG",
    clientId: "5vttb4nuqei73f2nhk89a1dhlf",
    region: "us-west-2",
    identityPoolId: "us-west-2:5798f46a-4304-40ef-a2a5-5dc587b76008",
    s3BucketForGoldenFile: "pricing-agent-golden-files",
    s3BucketForCSNFile: "pricing-agent-csn-files",
    s3BucketForM2SFile: "pricing-agent-term-mapping-files",
    apiGatewayHost: "78xw2e3xlg.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostWatchDog: "sa77qfu5ta.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAutocomplete: "a1t9o75cn2.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFinance: "0tkplfzntb.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostBlackSwan: "lpmeac8bi5.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostCheckup: "k69j6saiz6.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFringe: "c7qgdnh46b.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostVault: "s6ai6kgbl2.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTEAccrucial: "dhqb5xnj0a.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAIPAccrucial: "x5j2j2pl2l.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFBPAccrucial: "ti2kypp77b.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAWSCCForecast: "e8qror4w5f.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxTrendAnalysis: "t2qnwly0t3.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostLeaseRecon: "ip5x8bfy00.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostESGDashboard: "o7kku87lh4.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostACVForecastingDashboard: "4ak1uaoyg7.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostForecastPlatform: "enta74mqnj.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostApplauseSpendPlatform: "z037gz7s49.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostPayrollCashFund: "sfu8jvheq1.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxRecon: "2y8bzndqj5.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostSeesaw: "zzm3cy12el.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostMeetingDigest: "wi292nnxff.execute-api.us-west-2.amazonaws.com",
    oAuthConfig: {
        domain: "dev-fsot-ai-hub.auth.us-west-2.amazoncognito.com",
        redirectSignIn: `http://${window.location.host}`,
        redirectSignOut: `http://${window.location.host}`,
        responseType: "code",
        identityProvider: "MSAzureAD",
    },
    envBasePath: "/dev",
    AgentAssistAdminRoleArn:
        "arn:aws:iam::494570837593:role/AgentAssistCognitoUserPoo-AgentAssistUserPoolGroup-TAY3OY5UCC1E",
    AgentAssistUserRoleArn:
        "arn:aws:iam::494570837593:role/AgentAssistCognitoUserPoo-AgentAssistUserPoolGroup-1WQRDCQQBBE9R",
    biaAdminRole1Arn: "arn:aws:iam::494570837593:role/bia_admin_role_1",
    biaUserRole1Arn: "arn:aws:iam::494570837593:role/bia_user_role_1",
    biaAdminRole2Arn: "arn:aws:iam::494570837593:role/bia_admin_role_2",
    biaUserRole2Arn: "arn:aws:iam::494570837593:role/bia_user_role_2",
    biaAdminRole3Arn: "arn:aws:iam::494570837593:role/bia_admin_role_3",
    WatchdogAdminRoleArn:
        "arn:aws:iam::494570837593:role/watchdog-usergroups-WDUserPoolGroupAdminRole-3MNAKJIBJW8Y",
    WatchdogUserRoleArn:
        "arn:aws:iam::494570837593:role/watchdog-usergroups-WDUserPoolGroupUserRole-2VMMFXO4A8L5",
    SalesDashboardAdminRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-SalesDashboardUserPoolGr-L8QHL3ZQCLKM",
    SalesDashboardUserRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-SalesDashboardUserPoolGr-113OV5BFII5E5",
    ExpensesDashboardAdminRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-ExpensesDashboardUserPoo-1R4VQABPBVDZ",
    ExpensesDashboardUserRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-ExpensesDashboardUserPoo-7RZP5XJNKZPA",
    BlackSwanUserRoleArn:
        "arn:aws:iam::494570837593:role/black-swan-usergroups-BlackSwanUserPoolGroupUserRo-6YTSK5Z4ILV3",
    BlackSwanAdminRoleArn:
        "arn:aws:iam::494570837593:role/black-swan-usergroups-BlackSwanUserPoolGroupAdminR-2W0AK0F1SPBH",
    HealthcheckUserRoleArn:
        "arn:aws:iam::494570837593:role/healthcheck-usergroups-HealthcheckUserPoolGroupUse-1MOXOE74HT3NX",
    HealthcheckAdminRoleArn:
        "arn:aws:iam::494570837593:role/healthcheck-usergroups-HealthcheckUserPoolGroupAdm-KD63MS4SLNSO",
    FringeUserRoleArn:
        "arn:aws:iam::494570837593:role/fringe-usergroups-FringeUserPoolGroupUserRole-ERDPHE9639G8",
    FringeAdminRoleArn:
        "arn:aws:iam::494570837593:role/fringe-usergroups-FringeUserPoolGroupAdminRole-1D90TGHPJIJYL",
    VaultUserRoleArn:
        "arn:aws:iam::494570837593:role/vault-usergroups-VaultUserPoolGroupUserRole-V7TH0E92YYP",
    VaultAdminRoleArn:
        "arn:aws:iam::494570837593:role/vault-usergroups-VaultUserPoolGroupAdminRole-1LTBOSU4GOJIY",
    TEAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/te-accrucial-usergroups-TEAccrucialUserPoolGroupAd-GA6X8N4166H1",
    AIPAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/aip-accrucial-usergroups-AIPAccrucialUserPoolGroup-1K16L0Z85I6X7",
    FBPAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/fbp-accrucial-usergroups-FBPAccrucialUserPoolGroup-1C2M67MC1B89M",
    AWSCCForecastUserRoleArn:
        "arn:aws:iam::494570837593:role/aws-cc-forecast-user-grou-AwsCCForecastUserPoolGro-LLLBYWIFCZVJ",
    AWSCCForecastAdminRoleArn:
        "arn:aws:iam::494570837593:role/aws-cc-forecast-user-grou-AwsCCForecastUserPoolGro-GU3GUEVW2KT3",
    TaxTrendAnalysisUserRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-ZV7YK7ZJ2WO2",
    TaxTrendAnalysisAdminRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-F5E56PIQVUE9",
    LeaseReconAdminRoleArn:
        "arn:aws:iam::494570837593:role/lease-recon-usergroups-LeaseReconUserPoolGroupAdmi-1T6JXM1W52GR3",
    ESGDashboardAdminRoleArn: "arn:aws:iam::494570837593:role/esg_dashboard_admin_role",
    ACVForecastingDashboardAdminRoleArnTopline:
        "arn:aws:iam::494570837593:role/acv_forecasting_topline_admin_role",
    ACVForecastingDashboardAdminRoleArnAMER:
        "arn:aws:iam::494570837593:role/acv_forecasting_amer_admin_role",
    ForecastPlatformAdminRoleArn: "arn:aws:iam::494570837593:role/forecast_platform_admin_role",
    ApplauseSpendPlatformUserRoleArn:
        "arn:aws:iam::494570837593:role/applause_spend_platform_user_role",
    PayrollCashFundAdminRoleArn: "arn:aws:iam::494570837593:role/payroll_cash_fund_admin_role",
    TaxReconAdminRoleArn: "arn:aws:iam::494570837593:role/tax_recon_admin_role",
    SeesawAdminRoleArn: "arn:aws:iam::494570837593:role/seesaw_admin_role",
    MeetingDigestAdminRoleArn: "arn:aws:iam::494570837593:role/meeting_digest_admin_role",
    LumiTopicsAdminRoleArn: "arn:aws:iam::494570837593:role/lumi_topics_admin_role",
    FinbotAdminRoleArn: "arn:aws:iam::494570837593:role/finbot_admin_role",
};

const dev = {
    production: false,
    userPoolId: "us-west-2_ARR238dcG",
    clientId: "5vttb4nuqei73f2nhk89a1dhlf",
    region: "us-west-2",
    identityPoolId: "us-west-2:5798f46a-4304-40ef-a2a5-5dc587b76008",
    s3BucketForGoldenFile: "pricing-agent-golden-files",
    s3BucketForCSNFile: "pricing-agent-csn-files",
    s3BucketForM2SFile: "pricing-agent-term-mapping-files",
    apiGatewayHost: "78xw2e3xlg.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostWatchDog: "sa77qfu5ta.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAutocomplete: "a1t9o75cn2.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFinance: "0tkplfzntb.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostBlackSwan: "lpmeac8bi5.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostCheckup: "k69j6saiz6.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFringe: "c7qgdnh46b.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostVault: "s6ai6kgbl2.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTEAccrucial: "dhqb5xnj0a.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAIPAccrucial: "x5j2j2pl2l.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFBPAccrucial: "ti2kypp77b.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAWSCCForecast: "e8qror4w5f.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxTrendAnalysis: "t2qnwly0t3.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostLeaseRecon: "ip5x8bfy00.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostESGDashboard: "o7kku87lh4.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostACVForecastingDashboard: "4ak1uaoyg7.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostForecastPlatform: "enta74mqnj.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostApplauseSpendPlatform: "z037gz7s49.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostPayrollCashFund: "sfu8jvheq1.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxRecon: "2y8bzndqj5.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostSeesaw: "zzm3cy12el.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostMeetingDigest: "wi292nnxff.execute-api.us-west-2.amazonaws.com",
    oAuthConfig: {
        domain: "dev-fsot-ai-hub.auth.us-west-2.amazoncognito.com",
        redirectSignIn: `https://${window.location.host}`,
        redirectSignOut: `https://${window.location.host}`,
        responseType: "code",
        identityProvider: "MSAzureAD",
    },
    envBasePath: "/dev",
    AgentAssistAdminRoleArn:
        "arn:aws:iam::494570837593:role/AgentAssistCognitoUserPoo-AgentAssistUserPoolGroup-TAY3OY5UCC1E",
    AgentAssistUserRoleArn:
        "arn:aws:iam::494570837593:role/AgentAssistCognitoUserPoo-AgentAssistUserPoolGroup-1WQRDCQQBBE9R",
    biaAdminRole1Arn: "arn:aws:iam::494570837593:role/bia_admin_role_1",
    biaUserRole1Arn: "arn:aws:iam::494570837593:role/bia_user_role_1",
    biaAdminRole2Arn: "arn:aws:iam::494570837593:role/bia_admin_role_2",
    biaUserRole2Arn: "arn:aws:iam::494570837593:role/bia_user_role_2",
    biaAdminRole3Arn: "arn:aws:iam::494570837593:role/bia_admin_role_3",
    WatchdogAdminRoleArn:
        "arn:aws:iam::494570837593:role/watchdog-usergroups-WDUserPoolGroupAdminRole-3MNAKJIBJW8Y",
    WatchdogUserRoleArn:
        "arn:aws:iam::494570837593:role/watchdog-usergroups-WDUserPoolGroupUserRole-2VMMFXO4A8L5",
    SalesDashboardAdminRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-SalesDashboardUserPoolGr-L8QHL3ZQCLKM",
    SalesDashboardUserRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-SalesDashboardUserPoolGr-113OV5BFII5E5",
    ExpensesDashboardAdminRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-ExpensesDashboardUserPoo-1R4VQABPBVDZ",
    ExpensesDashboardUserRoleArn:
        "arn:aws:iam::494570837593:role/fsotaihub-financialdashbo-ExpensesDashboardUserPoo-7RZP5XJNKZPA",
    BlackSwanUserRoleArn:
        "arn:aws:iam::494570837593:role/black-swan-usergroups-BlackSwanUserPoolGroupUserRo-6YTSK5Z4ILV3",
    BlackSwanAdminRoleArn:
        "arn:aws:iam::494570837593:role/black-swan-usergroups-BlackSwanUserPoolGroupAdminR-2W0AK0F1SPBH",
    HealthcheckUserRoleArn:
        "arn:aws:iam::494570837593:role/healthcheck-usergroups-HealthcheckUserPoolGroupUse-1MOXOE74HT3NX",
    HealthcheckAdminRoleArn:
        "arn:aws:iam::494570837593:role/healthcheck-usergroups-HealthcheckUserPoolGroupAdm-KD63MS4SLNSO",
    FringeUserRoleArn:
        "arn:aws:iam::494570837593:role/fringe-usergroups-FringeUserPoolGroupUserRole-ERDPHE9639G8",
    FringeAdminRoleArn:
        "arn:aws:iam::494570837593:role/fringe-usergroups-FringeUserPoolGroupAdminRole-1D90TGHPJIJYL",
    VaultUserRoleArn:
        "arn:aws:iam::494570837593:role/vault-usergroups-VaultUserPoolGroupUserRole-V7TH0E92YYP",
    VaultAdminRoleArn:
        "arn:aws:iam::494570837593:role/vault-usergroups-VaultUserPoolGroupAdminRole-1LTBOSU4GOJIY",
    TEAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/te-accrucial-usergroups-TEAccrucialUserPoolGroupAd-GA6X8N4166H1",
    AIPAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/aip-accrucial-usergroups-AIPAccrucialUserPoolGroup-1K16L0Z85I6X7",
    FBPAccrucialAdminRoleArn:
        "arn:aws:iam::494570837593:role/fbp-accrucial-usergroups-FBPAccrucialUserPoolGroup-1C2M67MC1B89M",
    AWSCCForecastUserRoleArn:
        "arn:aws:iam::494570837593:role/aws-cc-forecast-user-grou-AwsCCForecastUserPoolGro-LLLBYWIFCZVJ",
    AWSCCForecastAdminRoleArn:
        "arn:aws:iam::494570837593:role/aws-cc-forecast-user-grou-AwsCCForecastUserPoolGro-GU3GUEVW2KT3",
    TaxTrendAnalysisUserRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-ZV7YK7ZJ2WO2",
    TaxTrendAnalysisAdminRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-F5E56PIQVUE9",
    LeaseReconAdminRoleArn:
        "arn:aws:iam::494570837593:role/lease-recon-usergroups-LeaseReconUserPoolGroupAdmi-1T6JXM1W52GR3",
    ESGDashboardAdminRoleArn: "arn:aws:iam::494570837593:role/esg_dashboard_admin_role",
    ACVForecastingDashboardAdminRoleArnTopline:
        "arn:aws:iam::494570837593:role/acv_forecasting_topline_admin_role",
    ACVForecastingDashboardAdminRoleArnAMER:
        "arn:aws:iam::494570837593:role/acv_forecasting_amer_admin_role",
    ForecastPlatformAdminRoleArn: "arn:aws:iam::494570837593:role/forecast_platform_admin_role",
    ApplauseSpendPlatformUserRoleArn:
        "arn:aws:iam::494570837593:role/applause_spend_platform_user_role",
    PayrollCashFundAdminRoleArn: "arn:aws:iam::494570837593:role/payroll_cash_fund_admin_role",
    TaxReconAdminRoleArn: "arn:aws:iam::494570837593:role/tax_recon_admin_role",
    SeesawAdminRoleArn: "arn:aws:iam::494570837593:role/seesaw_admin_role",
    MeetingDigestAdminRoleArn: "arn:aws:iam::494570837593:role/meeting_digest_admin_role",
    LumiTopicsAdminRoleArn: "arn:aws:iam::494570837593:role/lumi_topics_admin_role",
    FinbotAdminRoleArn: "arn:aws:iam::494570837593:role/finbot_admin_role",
};

const prod = {
    production: true,
    userPoolId: "us-west-2_MczNswh0S",
    clientId: "5lq1q5g22r1epniu9htpmh3et",
    region: "us-west-2",
    identityPoolId: "us-west-2:c7325f9f-4f88-4c2f-b41f-c4869fae45b0",
    s3BucketForGoldenFile: "fsot-pricing-agent-golden-files",
    s3BucketForCSNFile: "fsot-pricing-agent-csn-files",
    s3BucketForM2SFile: "fsot-pricing-agent-term-mapping-files",
    apiGatewayHost: "3b8zbwuodd.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostWatchDog: "lphhwnomie.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAutocomplete: "13gwnsstlb.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFinance: "62cqz3wre5.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostBlackSwan: "6zpw7j5w6h.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostCheckup: "3g8okr78bc.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFringe: "bt18c66av3.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostVault: "w11bybprwb.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTEAccrucial: "djhalkrfka.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAIPAccrucial: "jzu4hxha31.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostFBPAccrucial: "8pksjpxu70.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostAWSCCForecast: "i6yfif1gj1.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxTrendAnalysis: "t2qnwly0t3.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostLeaseRecon: "uon3gkp5i6.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostESGDashboard: "4i8c7yohoh.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostACVForecastingDashboard: "x18kilcs2g.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostForecastPlatform: "xtwx6r4mm8.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostApplauseSpendPlatform: "z037gz7s49.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostPayrollCashFund: "bvzqiczlp9.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostTaxRecon: "jxyf63i2vf.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostSeesaw: "dtrt93e2ra.execute-api.us-west-2.amazonaws.com",
    apiGatewayHostMeetingDigest: "1oet15yeub.execute-api.us-west-2.amazonaws.com",
    oAuthConfig: {
        domain: "fsot-ai-hub.auth.us-west-2.amazoncognito.com",
        redirectSignIn: `https://${window.location.host}`,
        redirectSignOut: `https://${window.location.host}`,
        responseType: "code",
        identityProvider: "MSAzureAD",
    },
    envBasePath: "/prod",
    AgentAssistAdminRoleArn:
        "arn:aws:iam::977292734568:role/Agent-Assist-Cognito-User-AgentAssistUserPoolGroup-DTE40MWKUK4O",
    AgentAssistUserRoleArn:
        "arn:aws:iam::977292734568:role/Agent-Assist-Cognito-User-AgentAssistUserPoolGroup-15GAWQUBMA11W",
    biaAdminRole1Arn: "arn:aws:iam::977292734568:role/bia_admin_role_1",
    biaUserRole1Arn: "arn:aws:iam::977292734568:role/bia_user_role_1",
    biaAdminRole2Arn: "arn:aws:iam::977292734568:role/bia_admin_role_2",
    biaUserRole2Arn: "arn:aws:iam::977292734568:role/bia_user_role_2",
    biaAdminRole3Arn: "arn:aws:iam::977292734568:role/bia_admin_role_3",
    WatchdogAdminRoleArn:
        "arn:aws:iam::977292734568:role/watchdog-django-integrati-WDUserPoolGroupAdminRole-14NJ5IEMELM0C",
    WatchdogUserRoleArn:
        "arn:aws:iam::977292734568:role/watchdog-django-integratio-WDUserPoolGroupUserRole-18YBMFLXEE4RX",
    SalesDashboardAdminRoleArn:
        "arn:aws:iam::977292734568:role/fsot-finanancialdashboard-SalesDashboardUserPoolGr-UIGPA38EELXO",
    SalesDashboardUserRoleArn:
        "arn:aws:iam::977292734568:role/fsot-finanancialdashboard-SalesDashboardUserPoolGr-HNT6Y9VJ6UCU",
    ExpensesDashboardAdminRoleArn:
        "arn:aws:iam::977292734568:role/fsot-finanancialdashboard-ExpensesDashboardUserPoo-QOOELEE9IZNY",
    ExpensesDashboardUserRoleArn:
        "arn:aws:iam::977292734568:role/fsot-finanancialdashboard-ExpensesDashboardUserPoo-MI8SLY5B263J",
    BlackSwanUserRoleArn:
        "arn:aws:iam::977292734568:role/black-swan-usergroups-BlackSwanUserPoolGroupUserRo-NIOC6I5HRN1J",
    BlackSwanAdminRoleArn:
        "arn:aws:iam::977292734568:role/black-swan-usergroups-BlackSwanUserPoolGroupAdminR-1CTQFPNOFTEQR",
    HealthcheckUserRoleArn:
        "arn:aws:iam::977292734568:role/healthcheck-usergroups-HealthcheckUserPoolGroupUse-1O9GR2OJWTXQC",
    HealthcheckAdminRoleArn:
        "arn:aws:iam::977292734568:role/healthcheck-usergroups-HealthcheckUserPoolGroupAdm-MAYN5COISPDX",
    FringeUserRoleArn:
        "arn:aws:iam::977292734568:role/fringe-usergroups-FringeUserPoolGroupUserRole-13NEX38AL10SS",
    FringeAdminRoleArn:
        "arn:aws:iam::977292734568:role/fringe-usergroups-FringeUserPoolGroupAdminRole-19HQBLPNVCRF8",
    VaultUserRoleArn:
        "arn:aws:iam::977292734568:role/vault-usergroups-VaultUserPoolGroupUserRole-1LLGTOK3U2GN0",
    VaultAdminRoleArn:
        "arn:aws:iam::977292734568:role/vault-usergroups-VaultUserPoolGroupAdminRole-1ABQZDHPDTVI4",
    TEAccrucialAdminRoleArn:
        "arn:aws:iam::977292734568:role/te-accrucial-groups-TEAccrucialUserPoolGroupAdminR-1MM78HA4FDSBJ",
    AIPAccrucialAdminRoleArn:
        "arn:aws:iam::977292734568:role/aip-accrucial-groups-AIPAccrucialUserPoolGroupAdmi-1MF81YJEAQMR6",
    FBPAccrucialAdminRoleArn:
        "arn:aws:iam::977292734568:role/fbp-accrucial-usergroups-FBPAccrucialUserPoolGroup-1BJZFEESMJSMV",
    AWSCCForecastUserRoleArn:
        "arn:aws:iam::977292734568:role/aws-cc-forecast-usergroup-AwsCCForecastUserPoolGro-158KL1RG4UCOK",
    AWSCCForecastAdminRoleArn:
        "arn:aws:iam::977292734568:role/aws-cc-forecast-usergroup-AwsCCForecastUserPoolGro-1IVUM2ZDOJ8XM",
    TaxTrendAnalysisUserRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-ZV7YK7ZJ2WO2",
    TaxTrendAnalysisAdminRoleArn:
        "arn:aws:iam::494570837593:role/tax-trend-analysis-usergr-TaxTrendAnalysisUserPool-F5E56PIQVUE9",
    LeaseReconAdminRoleArn:
        "arn:aws:iam::977292734568:role/lease-recon-usergroups-LeaseReconUserPoolGroupAdmi-HDDV2EJECXNT",
    ESGDashboardAdminRoleArn: "arn:aws:iam::977292734568:role/esg_dashboard_admin_role",
    ACVForecastingDashboardAdminRoleArnTopline:
        "arn:aws:iam::977292734568:role/acv_forecasting_topline_admin_role",
    ACVForecastingDashboardAdminRoleArnAMER:
        "arn:aws:iam::977292734568:role/acv_forecasting_amer_admin_role",
    ForecastPlatformAdminRoleArn: "arn:aws:iam::977292734568:role/forecast_platform_admin_role",
    ApplauseSpendPlatformUserRoleArn:
        "arn:aws:iam::977292734568:role/applause_spend_platform_user_role",
    PayrollCashFundAdminRoleArn: "arn:aws:iam::977292734568:role/payroll_cash_fund_admin_role",
    TaxReconAdminRoleArn: "arn:aws:iam::977292734568:role/tax_recon_admin_role",
    SeesawAdminRoleArn: "arn:aws:iam::977292734568:role/seesaw_admin_role",
    MeetingDigestAdminRoleArn: "arn:aws:iam::977292734568:role/meeting_digest_admin_role",
    LumiTopicsAdminRoleArn: "arn:aws:iam::977292734568:role/lumi_topics_admin_role",
    FinbotAdminRoleArn: "arn:aws:iam::977292734568:role/finbot_admin_role",
};

export const environment =
    process.env.REACT_APP_ENV === "local"
        ? local
        : process.env.REACT_APP_ENV === "development"
        ? dev
        : prod;
