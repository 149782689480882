import { environment } from "../../environment";

export const API_URLS = {
    watchDogDomainURL: environment.apiGatewayHost,
    agentAssistDomainURL: environment.apiGatewayHost,
    salesDashboardDomainUrl: environment.apiGatewayHostFinance,
    expensesDashboardDomainUrl: environment.apiGatewayHostFinance,
    LandingPageDomainUrls: environment.apiGatewayHost,
    watchDogConfigUrls: {
        search: environment.envBasePath + "/watchdog/search",
        owsData: environment.envBasePath + "/watchdog/ows-entity",
        owsEntityReport: environment.envBasePath + "/watchdog/ows-entity/report",
        owsReportExport: environment.envBasePath + "/watchdog/ows-entity/report/export",
    },
    configUrls: {
        retrieveGoldenFileURL: environment.envBasePath + "/golden/rows",
        retirveUploadedFileDetails: environment.envBasePath + "/uploads",
        searchURL: environment.envBasePath + "/search",
        exportURL: environment.envBasePath + "/export",
        mappingToolURL: environment.envBasePath + "/term/search",
        retrieveProcessedDates: environment.envBasePath + "/processed/dates",
        dropdownConfigURL: environment.envBasePath + "/dropdown/search",
        retrieveAutoCompData: environment.envBasePath + "/search/query",
        retrieveRecentSearch: environment.envBasePath + "/recent/get",
        retrieveTopSearch: environment.envBasePath + "/top/get",
        addRecentSearch: environment.envBasePath + "/recent/add",
    },
    salesDashboardUrls: {
        retrieveForecastData: environment.envBasePath + "/sales/forecast",
        retrieveFilters: environment.envBasePath + "/sales/filters",
    },
    expensesDashboardUrls: {
        retrieveForecastData: environment.envBasePath + "/expense/forecast",
    },
    landingPageUrls: {
        retrieveStatisticsData: environment.envBasePath + "/getStatistics",
        sendLandingPageFeedback: environment.envBasePath + "/feedbacks",
        sendModulesFeedback: environment.envBasePath + "/feedback",
        sendAccessRequest: environment.envBasePath + "/requestAccess",
    },
    blackSwanUrls: {
        uploadFile: environment.envBasePath + "/black-swan/upload",
        processUploadedFile: environment.envBasePath + "/black-swan/process",
        getAnomalies: environment.envBasePath + "/black-swan/anomaly",
        getAnomaliesData: environment.envBasePath + "/black-swan/fetch_anomaly_data",
        getUploadedFiles: environment.envBasePath + "/black-swan/files",
        updateAnomalyData: environment.envBasePath + "/black-swan/update_anomaly_data",
        getProcessStatus: environment.envBasePath + "/black-swan/script/status",
        exportURL: environment.envBasePath + "/black-swan/export",
        columnValidStatus: environment.envBasePath + "/black-swan/validate_status",
    },
    checkupUrls: {
        getMasterData: environment.envBasePath + "/checkup/get_master_data",
        getRepList: environment.envBasePath + "/checkup/rep_list",
        getChartsData: environment.envBasePath + "/checkup/get_charts_data",
        exportMasterData: environment.envBasePath + "/checkup/export",
        upload: environment.envBasePath + "/checkup/upload",
        uploadComplete: environment.envBasePath + "/checkup/upload/complete",
        uploadHistory: environment.envBasePath + "/checkup/upload/history",
    },
    fringeUrls: {
        upload: environment.envBasePath + "/fringe/upload",
        uploadComplete: environment.envBasePath + "/fringe/upload/complete",
        filters: environment.envBasePath + "/fringe/filters",
        data: environment.envBasePath + "/fringe/data",
        forecast: environment.envBasePath + "/fringe/forecast",
        uploadHistory: environment.envBasePath + "/fringe/upload/history",
    },
    vaultUrls: {
        upload: environment.envBasePath + "/vault/upload",
        uploadComplete: environment.envBasePath + "/vault/upload/complete",
        filters: environment.envBasePath + "/vault/dropdown",
        forecast: environment.envBasePath + "/vault/forecast",
    },
    accrucialUrls: {
        upload: environment.envBasePath + "/accrucial/upload",
        forecast: environment.envBasePath + "/accrucial/forecast",
        te: {
            masterData: environment.envBasePath + "/accrucial/te/get_master_data",
            export: environment.envBasePath + "/accrucial/te/export",
            charts: environment.envBasePath + "/accrucial/te/stats",
        },
        aip: {
            masterData: environment.envBasePath + "/accrucial/aip/get_master_data",
            export: environment.envBasePath + "/accrucial/aip/export",
            charts: environment.envBasePath + "/accrucial/aip/charts",
            files: environment.envBasePath + "/accrucial/aip/files",
            booked: environment.envBasePath + "/accrucial/aip/booked",
            attainmentHistory: environment.envBasePath + "/accrucial/aip/attainment/history",
            be_script: environment.envBasePath + "/accrucial/aip/be/script",
            be_export_files: environment.envBasePath + "/accrucial/aip/export/be/files",
            be_export: environment.envBasePath + "/accrucial/aip/export/be",
        },
        fbp: {
            masterData: environment.envBasePath + "/accrucial/fbp/get_master_data",
        },
    },
    awsCCUrls: {
        masterData: environment.envBasePath + "/aws-cc-forecast/master-data",
        forecast: environment.envBasePath + "/aws-cc-forecast/forecast",
        export: environment.envBasePath + "/aws-cc-forecast/export",
        files: environment.envBasePath + "/aws-cc-forecast/files",
        booked: environment.envBasePath + "/aws-cc-forecast/booked",
        pluggedCost: environment.envBasePath + "/aws-cc-forecast/plugged-cost",
    },
    taxTrendAnalysisUrls: {
        upload: environment.envBasePath + "/tax-trend-analysis/upload",
        filters: environment.envBasePath + "/tax-trend-analysis/dropdown",
        dashboard: environment.envBasePath + "/tax-trend-analysis/dashboard",
    },
    leaseReconUrls: {
        upload: environment.envBasePath + "/lease-recon/upload",
        master_data: environment.envBasePath + "/lease-recon/master_data",
        run: environment.envBasePath + "/lease-recon/run",
    },
    esgDashboardUrls: {
        master_data: environment.envBasePath + "/esg-dashboard/master_data",
        presigned_url: environment.envBasePath + "/esg-dashboard/export_presigned_url",
    },
    acvForecastingDashboardUrls: {
        topline: {
            filters: environment.envBasePath + "/acv-forecast/topline/filters",
            charts: environment.envBasePath + "/acv-forecast/topline/charts",
            export: environment.envBasePath + "/acv-forecast/topline/export",
        },
        amer: {
            filters: environment.envBasePath + "/acv-forecast/amer/filters",
            charts: environment.envBasePath + "/acv-forecast/amer/charts",
            export: environment.envBasePath + "/acv-forecast/amer/export",
        },
    },
    forecastPlatformUrls: {
        upload: environment.envBasePath + "/forecast-platform/upload",
        run_train: environment.envBasePath + "/forecast-platform/run_train_model",
        run_forecast: environment.envBasePath + "/forecast-platform/run_forecast",
        status: environment.envBasePath + "/forecast-platform/status",
        history: environment.envBasePath + "/forecast-platform/historical_data",
        action: environment.envBasePath + "/forecast-platform/forecast_action",
    },
    applauseSpendPlatformUrls: {
        masterData: environment.envBasePath + "/applause-platform/master-data",
        applauseData: environment.envBasePath + "/applause-platform/applause-data",
    },
    payrollCashFundUrls: {
        masterData: environment.envBasePath + "/payroll-cash-fund-dashboard/master_data",
        adjustments: environment.envBasePath + "/payroll-cash-fund-dashboard/adjustments/update",
    },
    taxReconUrls: {
        upload: environment.envBasePath + "/tax-recon/upload",
        master_data: environment.envBasePath + "/tax-recon/master_data",
        run: environment.envBasePath + "/tax-recon/run",
        exports: environment.envBasePath + "/tax-recon/exports",
        export: environment.envBasePath + "/tax-recon/export",
    },
    seesawUrls: {
        upload: environment.envBasePath + "/seesaw/upload",
        master_data: environment.envBasePath + "/seesaw/master_data",
        simulation: environment.envBasePath + "/seesaw/simulation",
        status: environment.envBasePath + "/seesaw/status",
    },
    meetingDigestUrls: {
        upload: environment.envBasePath + "/meeting-digest/upload",
        process: environment.envBasePath + "/meeting-digest/process",
        status: environment.envBasePath + "/meeting-digest/status",
        exports: environment.envBasePath + "/meeting-digest/exports",
        export: environment.envBasePath + "/meeting-digest/export",
    },
};
