import React, { Fragment, useState, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../context";
import { BrowserTitleContext } from "../../context/browserTitle";
import history from "../../utils/history";
import { getItem, removeItem } from "../../utils/LocalStorage";
import { signOut } from "../../services/aws-http-services";

const AuthHeader = ({ username, children }) => {
    const [dropdown, showDropDown] = useState(false);
    const { setUnauthStatus } = useContext(AuthContext);
    const { setBrowserTitle } = useContext(BrowserTitleContext);

    const _handleDropDown = () => {
        showDropDown(!dropdown);
    };

    const _handleLogOut = () => {
        // signOut()
        // setUnauthStatus();
        setBrowserTitle("Autodesk | FSOT AI Dashboard");
        removeItem("ad_module");
        removeItem("isAdmin");
        history.push("/");
    };
    return (
        <Fragment>
            {children}
            {window.location.pathname === "/about-us" ? null : (
                <div className="profile ml-4 d-flex justify-content-end">
                    <div className="dropdown">
                        <a
                            className="btn dropdown-toggle profile-name"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {username ? username : "Hello"}
                            <img
                                className="placeholder"
                                alt="User"
                                src="assets/img/placeholder-profile-sq.jpg"
                            />
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuLink"
                        >
                            <a onClick={_handleLogOut} className="dropdown-item">
                                Logout
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

AuthContext.propTypes = {
    username: PropTypes.string,
    children: PropTypes.node,
};

export default AuthHeader;
